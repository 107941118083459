import React, { useState, useEffect, useRef } from 'react';
import '../styles/Services.css';
import ContactForm from './Form';
import Benefits from './Benefits';  // Importando Benefits
import Benefits2 from './Benefits2';  // Importando Benefits2

const Services = () => {

    const [usedLinks, setUsedLinks] = useState([]);
    // Controle de visibilidade de cada card
    const [isVisible, setIsVisible] = useState({ nail: true, brow: false });

    // Referências para os botões e texto
    const cursoBtnNailRef = useRef(null);
    const cursoBtnBrowRef = useRef(null);
    const cursoNailTextRef = useRef(null);
    const cursoBrowTextRef = useRef(null);

    // Função para alternar a visibilidade do curso de Nail
    const handleCursoNailClick = () => {
        setIsVisible(prevState => ({ nail: !prevState.nail, brow: false }));
    };

    // Função para alternar a visibilidade do curso de Brow
    const handleCursoBrowClick = () => {
        setIsVisible(prevState => ({ nail: false, brow: !prevState.brow }));
    };

    // Efeito para adicionar ou remover classes de visibilidade baseado no estado
    useEffect(() => {
        const cursoNailText = cursoNailTextRef.current;
        const cursoBrowText = cursoBrowTextRef.current;

        if (cursoNailText) {
            cursoNailText.classList.toggle('visible', isVisible.nail);
            cursoNailText.classList.toggle('hidden', !isVisible.nail);
        }

        if (cursoBrowText) {
            cursoBrowText.classList.toggle('visible', isVisible.brow);
            cursoBrowText.classList.toggle('hidden', !isVisible.brow);
        }
    }, [isVisible]);

    return (
        <div className="services" id="services">
            <h2>Cursos Profissionalizantes</h2>

            <div className="container">
            
                {/* Card para Nail Designer */}
                <div className="card1 hidden" ref={cursoBtnNailRef} onClick={handleCursoNailClick}>
                    <h1>Nail Designer</h1>
                    <h3>Vagas Abertas!</h3>
                </div>
                
                {/* Card para Brow Designer */}
                <div className="card2 visible" ref={cursoBtnBrowRef} onClick={handleCursoBrowClick}>
                    <h1>Brow Designer</h1>
                    <h3>LANÇAMENTO!</h3>
                </div>

            </div>
            {/* Conteúdo de Nail Designer */}
            <div id="text-curso" ref={cursoNailTextRef} className={isVisible.nail ? "visible" : "hidden"}>
                <div id='txt1'>
                    <h1>Conteúdo Online e Aulas Presenciais:</h1>
                    <h3>Instrutor(a) presencial para dar o APOIO necessário aos alunos.</h3>
                    <h1>Módulo Duplo:</h1>
                    <h3>Apostilas contendo módulo teórico e prático.</h3>
                    <h1>Modalidade: </h1>
                    <h3>Semi-presencial</h3>
                    <h1>Carga Horária:</h1>
                    <h3>60 Horas</h3>
                    <h1>Instrutor(a):</h1>
                    <h3>Marli Mendes</h3>
                    <h1>Acesso Imediato:</h1>
                    <h3>Assim que a sua inscrição for confirmada <br /> você vai receber o acesso IMEDIATAMENTE em seu email.</h3><br />
                </div>
                <div id='txt2'>
                    {/* Conteúdo adicional */}
                    <p>Em uma abordagem dinâmica semi-presencial, nossa academia oferece uma experiência única, combinando aprendizado <i><b>prático presencial</b></i> e <i><b>teórico online</b></i> para prepará-lo para o mercado de trabalho.</p> <br />
                    <p>Sob a orientação experiente de nossa dedicada professora, você será imerso em um <b>conteúdo abrangente, detalhado e atualizado.</b> Com mais de <b>20 anos</b> de experiência no ramo e um histórico de sucesso na formação de centenas de profissionais, nossa <b>professora presencial</b> é o alicerce perfeito para a sua jornada rumo à <b>excelência</b> na profissão de <b>manicure</b>.</p><br />
                    <p>Desde técnicas básicas até os mais avançados truques do ofício, nosso curso é projetado para capacitá-lo com as habilidades essenciais necessárias para se destacar no mercado. Você aprenderá não apenas a embelezar as <b>unhas</b>, mas também a cuidar da <b>saúde</b> e bem-estar de seus clientes, garantindo serviços de <b>qualidade</b> e segurança.</p><br />
                    <p>Não importa se você está dando os primeiros passos em sua carreira ou buscando aprimoramento profissional, o nosso curso oferece o suporte e conhecimento necessário para você alcançar seus objetivos. Prepare-se para uma jornada emocionante no mundo da <b>beleza</b> e torne-se o profissional de sucesso que você sempre quis ser com o nosso curso.</p><br />
                    <h2>MODALIDADES DISPONÍVEIS</h2> <br /><br />
                </div>
                <div className='item2'>
                    {/* Componentes de Benefits para Nail Designer */}
                    <Benefits courseId="naildesign" usedLinks={usedLinks} setUsedLinks={setUsedLinks}/>
                    <Benefits courseId="naildesign2" usedLinks={usedLinks} setUsedLinks={setUsedLinks}/>
                </div>
            </div>
            
            {/* Conteúdo de Brow Designer */}
            <div id="text-curso2" ref={cursoBrowTextRef} className={isVisible.brow ? "visible" : "hidden"}>
                <div id='txt1'>
                    <h1>Conteúdo Online e Aulas Presenciais:</h1>
                    <h3>Instrutor(a) presencial para dar o APOIO necessário aos alunos.</h3>
                    <h1>Módulo Duplo:</h1>
                    <h3>Apostilas contendo módulo teórico e prático.</h3>
                    <h1>Modalidade: </h1>
                    <h3>Semi-presencial</h3>
                    <h1>Carga Horária:</h1>
                    <h3>16 Horas</h3>
                    <h1>Instrutor(a):</h1>
                    <h3>Mirian Matos</h3>
                    <h1>Acesso Imediato:</h1>
                    <h3>Assim que a sua inscrição for confirmada <br /> você vai receber o acesso IMEDIATAMENTE em seu email.</h3><br />
                </div>
                <div id='txt2'>
                    {/* Conteúdo adicional */}
                    <p>Em uma abordagem dinâmica semi-presencial, nossa academia oferece uma experiência única, combinando aprendizado <i><b>prático presencial</b></i> e <i><b>teórico online</b></i> para prepará-lo para o mercado de trabalho.</p> <br />
                    <p>Sob a orientação experiente de nossa dedicada professora, você será imerso em um <b>conteúdo abrangente, detalhado e atualizado.</b> Com mais de <b>10 anos</b> de experiência no ramo e um histórico de sucesso na formação de centenas de profissionais, nossa <b>professora presencial</b> é o alicerce perfeito para a sua jornada rumo à <b>excelência</b> na profissão de <b>designer de sobrancelhas</b>.</p><br />
                    <p>Desde técnicas básicas até os mais avançados truques do ofício, nosso curso é projetado para capacitá-lo com as habilidades essenciais necessárias para se destacar no mercado. Você aprenderá não apenas a embelezar as <b>sobrancelhas</b>, mas também a cuidar da <b>saúde</b> e bem-estar de seus clientes, garantindo serviços de <b>qualidade</b> e segurança.</p><br />
                    <p>Não importa se você está dando os primeiros passos em sua carreira ou buscando aprimoramento profissional, o nosso curso oferece o suporte e conhecimento necessário para você alcançar seus objetivos. Prepare-se para uma jornada emocionante no mundo da <b>beleza</b> e torne-se o profissional de sucesso que você sempre quis ser com o nosso curso.</p><br /><br />
                    <h2>MODALIDADES DISPONÍVEIS</h2> <br /><br />
                </div>
                <div className='item2'>
                    {/* Componentes de Benefits2 para Brow Designer */}
                    <Benefits2 courseId="browdesign" usedLinks={usedLinks} setUsedLinks={setUsedLinks}/>
                    <Benefits2 courseId="browdesign2" usedLinks={usedLinks} setUsedLinks={setUsedLinks}/>
                </div>
            </div>
            <div id='texto_chamada'>
                <h3>Ficou alguma dúvida?</h3>
                <h2>Fale conosco!</h2>
            </div>
            
            {/* Formulário de Contato */}
            <div className="item1">
                <ContactForm />
            </div>
        </div>
    );
};

export default Services;