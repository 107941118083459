import React, { useState, useEffect } from "react";
import '../styles/Benefits.css';
import Available from "./icons/available.svg";
import Unavailable from "./icons/unavailable.svg";
import HotmartComponent from './HotmartComponent';
import courseData from './courseData';

const Benefits = ({ courseId, usedLinks, setUsedLinks }) => {
  const [btnLink, setBtnLink] = useState('');

  // Encontra o curso com base no courseId
  const course = courseData.find(course => course.id_curso === courseId);

  // useEffect precisa ser chamado incondicionalmente
  useEffect(() => {
    if (course) {
      // Função para buscar o btn_link do curso e garantir que não seja duplicado
      const findCourseLink = (id) => {
        const course = courseData.find((course) => course.id_curso === id);
        if (course) {
          if (!usedLinks.includes(course.btn_link)) {
            // Se o link ainda não foi utilizado, o retorna
            setUsedLinks((prevLinks) => [...prevLinks, course.btn_link]); // Atualiza links utilizados
            return course.btn_link;
          } else {
            // Se o link já foi utilizado, pega o próximo link disponível
            const nextCourse = courseData.find(
              (courseItem) => !usedLinks.includes(courseItem.btn_link)
            );
            if (nextCourse) {
              setUsedLinks((prevLinks) => [...prevLinks, nextCourse.btn_link]);
              return nextCourse.btn_link;
            }
          }
        }
        return '#'; // Retorna '#' se o curso não for encontrado ou se não houver link disponível
      };

      const link = findCourseLink(courseId);
      setBtnLink(link);
    }
  }, [courseId, usedLinks, setUsedLinks, course]);

  // Verifica se o curso existe após o useEffect
  if (!course) {
    return <p>Curso não encontrado.</p>;
  }

  return (
    <div id="beneficio">
      <h2>{course.title}</h2>

      {course.details.map((detail, detailIndex) => (
        <div key={detailIndex} className="benefit-item">
          <img
            src={detail.available ? Available : Unavailable}
            alt={detail.available ? "Incluso" : "Não Incluso"}
            id={detail.available ? "available" : "unavailable"}
          />
          <h3>{detail.text}</h3>
        </div>
      ))}

      <div className="price-btn">
        <p>Por apenas</p>
      </div>
      <div className="price"> 
        <h3>Em até {course.price}</h3>
        {/* Passa o link correto para o HotmartComponent */}
        <HotmartComponent id_curso={courseId} btnLink={btnLink} />
        <br /> <br />
        <a href={course.course_link} target="__blank" id="saibamais">Saiba Mais+</a> 
        <br /><br />
      </div>
    </div>
  );
};

export default Benefits;