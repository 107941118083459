import React, { useState } from "react";
import emailjs from 'emailjs-com';
import '../styles/TrabalheConosco.css';

const TrabalheConosco = () => {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.nome,
      email: formData.email
    }

    emailjs.send(
      'service_vqntwwg',
      'template_3200iof',
      templateParams,
      'LNgFuCHuOPRxlj5Z8'
    ).then(
      (result) => {
        console.log(result.text);
        alert('Email enviado com sucesso!');
      },
      (error) => {
        console.log(error.text);
        alert('Erro ao enviar o email.');
      }
    );
  };

  return (
    <div className="tconosco" id="tconosco">
      <h2>Trabalhe Conosco</h2>
      <h3>Já é profissional da beleza e está procurando uma oportunidade? <br/> Entre em contato conosco!</h3>
      <form onSubmit={handleSubmit}>
        <label htmlFor="nome1">Nome:</label>
        <input
          type="nome1"
          id="nome"
          name="nome"
          value={formData.nome}
          onChange={handleChange}
          required
        />

        <label htmlFor="email1">E-mail:</label>
        <input
          type="email1"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        /><br/>

        <button type="submit">Enviar</button>
      </form>
    </div>
  );
};

export default TrabalheConosco;