const courseData = [
    {
      id_curso: "naildesign",
      title: "Curso Online - EAD",
      details: [
        { text: "Acesso vitalício a 2 apostilas digitais.", available: true },
        { text: "10 videoaulas teóricas e práticas.", available: true },
        { text: "Certificado de conclusão garantido.", available: true },
        { text: "Acesso à comunidade de networking.", available: false },
        { text: "Aulas presenciais teóricas e práticas nos estúdios profissionais da Academia Girassol.", available: false },
        { text: "60h de aulas práticas garantidas no certificado.", available: false },
        { text: "Suporte exclusivo de instrutor profissional.", available: false }
      ],
      price: "6x R$55,16/Mês",
      course_link: "https://academiagirassol.hotmart.host/nail-designer",
      btn_link: "https://hotm.art/DesignUnhasGirassol"
    },
    {
      id_curso: "naildesign2",
      title: "Curso Semi-presencial",
      details: [
        { text: "Acesso vitalício a 2 apostilas digitais.", available: true },
        { text: "10 videoaulas teóricas e práticas.", available: true },
        { text: "Certificado de conclusão garantido.", available: true },
        { text: "Acesso à comunidade de networking.", available: true },
        { text: "Aulas presenciais teóricas e práticas nos estúdios profissionais da Academia Girassol.", available: true },
        { text: "60h de aulas práticas garantidas no certificado.", available: true },
        { text: "Suporte exclusivo de instrutor profissional.", available: true }
      ],
      price: "12x R$99,70/Mês",
      course_link: "https://academiagirassol.hotmart.host/nail-designer",
      btn_link: "https://pay.hotmart.com/W92532436G?off=1dxtukrk&checkoutMode=10"
    },
    {
      id_curso: "browdesign",
      title: "Curso Online - EAD",
      details: [
        { text: "Acesso vitalício a 1 apostila digital.", available: true },
        { text: "5 videoaulas teóricas e práticas.", available: true },
        { text: "Certificado de conclusão garantido.", available: true },
        { text: "Acesso à comunidade de networking.", available: false },
        { text: "Aulas presenciais teóricas e práticas nos estúdios profissionais da Academia Girassol.", available: false },
        { text: "16h de aulas práticas garantidas no certificado.", available: false },
        { text: "Suporte exclusivo de instrutor profissional.", available: false }
      ],
      price: "4x R$49,97/Mês",
      course_link: "https://academiagirassol.hotmart.host/brow-designer",
      btn_link: "https://hotm.art/DesignBrowGirassol"
    },
    {
      id_curso: "browdesign2",
      title: "Curso Semi-presencial",
      details: [
        { text: "Acesso vitalício a 1 apostila digital.", available: true },
        { text: "5 videoaulas teóricas e práticas.", available: true },
        { text: "Certificado de conclusão garantido.", available: true },
        { text: "Acesso à comunidade de networking.", available: true },
        { text: "Aulas presenciais teóricas e práticas nos estúdios profissionais da Academia Girassol.", available: true },
        { text: "16h de aulas práticas garantidas no certificado.", available: true },
        { text: "Suporte exclusivo de instrutor profissional.", available: true }
      ],
      price: "10x R$79,99/Mês",
      course_link: "https://academiagirassol.hotmart.host/brow-designer",
      btn_link: "https://pay.hotmart.com/Y93758838Y?checkoutMode=10"
    },
  ];

export default courseData;