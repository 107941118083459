import React from 'react';
import '../styles/Topicon.css';
            
const Topicon = () => (
   <>  
        <a href="#Inicio" id="topo"> </a>
    </>


);

export default Topicon;
            