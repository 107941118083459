import './App.css';
import Header from './components/Header';
import Main from './components/Main';
import HeroSlider from './components/HeroSlider';
import Sobre from './components/Sobre';
import Services from './components/Services';
import Topicon from './components/Topicon';
import Footer from './components/Footer';
import Contato from './components/Contato';
import TrabalheConosco from './components/TrabalheConosco';


function App() {
  return (
    
    <>
      <Header />

      <Main />

      <HeroSlider />

      <Sobre />

      <Services />

      <TrabalheConosco />

      <Contato />

      <Footer />

      <Topicon /> 
    </>
  );
}

export default App;