import React, { useEffect, useState } from 'react';
import courseData from './courseData';

const HotmartComponent = ({ id_curso }) => {
  const [btnLink, setBtnLink] = useState('');

  useEffect(() => {
    // Função para buscar o btn_link pelo id_curso
    const findCourseLink = (id) => {
      const course = courseData.find((course) => course.id_curso === id);
      return course ? course.btn_link : '#'; // Retorna '#' se o curso não for encontrado
    };

    const link = findCourseLink(id_curso);
    setBtnLink(link);

    // Carrega o script da Hotmart apenas uma vez
    function importHotmart() {
      const existingScript = document.querySelector('script[src="https://static.hotmart.com/checkout/widget.min.js"]');
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://static.hotmart.com/checkout/widget.min.js';
        script.async = true;
        script.onload = () => {
          console.log('Hotmart script loaded successfully');
        };
        script.onerror = () => {
          console.error('Error loading Hotmart script');
        };
        document.head.appendChild(script);
      }
    }

    importHotmart();
  }, [id_curso]); // Rodar o efeito toda vez que id_curso mudar

  return (
    <a href={btnLink} className="hotmart-fb hotmart__button-checkout">
      <button id="Inscrever4">Comprar Agora</button>
    </a>
  );
};

export default HotmartComponent;