import React from 'react';
import '../styles/Sobre.css';

const Sobre = () => (
            <section>
                <div className="sobre" id="sobre">
                    <h2>Sobre nós</h2>
                    <div className="textoSobre">
                        <div className='fade-in-section'>
                        <p>Bem-vindo à Academia de Beleza Girassol, um espaço dedicado à transformação, não apenas na aparência, mas também na vida das pessoas. Somos uma instituição de ensino especializada em cursos de beleza, comprometida em proporcionar conhecimento técnico e prático de alta qualidade para todos que desejam ingressar e prosperar no setor de beleza.<br/>
                            </p></div>
                            <div className='fade-in-section'>
                                <p><br/><b>Nossa Missão:</b><br/>
                                Nossa missão é empoderar indivíduos através da educação e formação profissional, ajudando-os a alcançar suas metas e sonhos no mundo da beleza. Acreditamos que a educação transforma vidas e que a beleza é uma ferramenta poderosa para o bem-estar e a autoestima.<br/>
                                </p></div>
                                <div className='fade-in-section'>
                                    <p><br/><b>Cursos de Qualidade</b><br/>
                                    Nossos cursos são ministrados por profissionais altamente qualificados e experientes, que estão prontos para compartilhar seus conhecimentos e habilidades com nossos alunos.<br/>
                                    </p></div>
                                    <div className='fade-in-section'>
                                    <p><br/><b>Compromisso Social</b><br/>
                                        Na Academia de Beleza Girassol, acreditamos que a educação deve ser acessível a todos. Por isso, em cada turma oferecemos duas vagas sociais, que são bolsas de estudo 100% gratuitas destinadas a pessoas carentes. Este programa visa proporcionar oportunidades iguais para todos, permitindo que indivíduos em situações econômicas desfavoráveis possam acessar uma formação de qualidade e, assim, mudar suas vidas para melhor.<br/>
                                        </p></div>
                                        <div className='fade-in-section'>
                                        <p><br/><b>Nossa Visão</b><br/>
                                            Visamos ser reconhecidos como referência em educação na área de beleza, não apenas pelo nosso compromisso com a qualidade do ensino, mas também pelo nosso impacto social positivo. Queremos inspirar e capacitar nossos alunos para que se tornem profissionais excepcionais e cidadãos conscientes, capazes de contribuir positivamente para a sociedade.<br/>
                                            </p></div>
                                            <div className='fade-in-section'>
                                                <p><br/><b>Junte-se a Nós</b><br/>
                                                Se você sonha em construir uma carreira de sucesso no mundo da beleza, venha fazer parte da Academia de Beleza Girassol. Aqui, você encontrará uma comunidade acolhedora e dedicada ao crescimento pessoal e profissional de cada aluno.<br/>
                                                </p></div>
                                                <div className='fade-in-section'>
                                                <p><br/><b>Localização:</b><br/>
                                                Estamos localizados em varias localidades da região de Campinas.<br/>
                                                Vagas sociais estão disponiveis em parceria com a <b>Associação de Moradores Chico Mendes</b>.
                                                <br/></p>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4476.651984460917!2d-47.12357008836984!3d-22.981494279115793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8c9f37780bdfb%3A0x8c826e3918cb75d0!2sR.%20Carmem%20de%20%C3%82ngelis%20Nicoleti%20-%20Campinas%2C%20SP%2C%2013054-532!5e1!3m2!1spt-BR!2sbr!4v1727198915628!5m2!1spt-BR!2sbr" width="150" height="150" title='Vagas Sociais aqui!' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
                                                <br/>
                                                <p>
                                                R. Carmem de Ângelis Nicoleti - Dic V <br/> Campinas - SP, 13054-532
                                                </p></div>
                                                <div className='fade-in-section'>
                                                    <p><br/><b>Academia de Beleza Girassol – Florescendo Vidas.</b></p>
                                                </div>
                        
                    </div>
                    <a href="https://wa.me/send?phone=5519978167330&text=Olá!%20Eu%20gostaria%20de%20saber%20mais%20informações%20sobre%20um%20curso.">
                        <button id="clique">Quero transformar minha vida!</button>
                    </a>
                </div>
            </section>
);

export default Sobre;