import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../styles/Form.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    phone: '',
    subject: '',
    mensagem: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.nome,
      phone: formData.phone,
      subject: formData.subject,
      message: formData.mensagem,
      email: formData.email
    };

    emailjs.send(
      'service_vqntwwg',
      'template_ml3as8a',
      templateParams,
      'LNgFuCHuOPRxlj5Z8'
    ).then(
      (result) => {
        console.log(result.text);
        alert('Email enviado com sucesso!');
      },
      (error) => {
        console.log(error.text);
        alert('Erro ao enviar o email.');
      }
    );
  };

  return (

    <div id='form-bg'> 
    <form onSubmit={handleSubmit} className="form">
      <div style={{ width: '100%' }}>
        <label htmlFor="nome" className="label">Nome:</label>
        <input
          type="nome"
          id="nome"
          name="nome"
          placeholder='Digite aqui seu nome completo.'
          value={formData.nome}
          onChange={handleChange}
          className="input"
          required
        />
      </div>
      <div style={{ width: '100%' }}>
        <label htmlFor="phone" className="label">Telefone:</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder='Digite aqui seu telefone para contato.'
          value={formData.phone}
          onChange={handleChange}
          className="input"
          required
        />
      </div>
      <div style={{ width: '100%' }}>
        <label htmlFor="email" className="label">E-mail:</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder='Digite aqui seu e-mail principal.'
          value={formData.email}
          onChange={handleChange}
          className="input"
          required
        />
      </div>
      <div style={{ width: '100%' }}>
        <label htmlFor="subject" className="label">Assunto:</label>
        <select
          id="subject"
          name="subject"
          placeholder='Selecione o curso desejado.'
          value={formData.subject}
          onChange={handleChange}
          className="input"
          required
        >
          <option value="Curso de Design em Unhas">Curso de Design em Unhas</option>
          <option value="Curso de Design em Unhas Vaga Social">Curso de Design em Unhas VAGA SOCIAL</option>
          <option value="Curso de Design em Sobrancelhas">Curso de Design em Sobrancelhas</option>
          <option value="Curso de Design em Sobrancelhas">Curso de Design em Sobrancelhas VAGA SOCIAL</option>
        </select>
      </div>
      <div style={{ width: '100%' }}>
        <label htmlFor="mensagem" className="label">Mensagem:</label>
        <textarea
          type="text"
          id="mensagem"
          name="mensagem"
          value={`Olá, Eu sou ${formData.nome}.\n Quero TRANSFORMAR minha vida com o \n ${formData.subject}. \n Me envie mais informações, por favor!`}
          className="input textarea"
          readOnly
        />
      </div>
      <button
        type="submit"
        className="button"
      >
        Quero mais informações!
      </button>
    </form>
  </div>
  );
};

export default ContactForm;